import React, { useState } from "react"
import Layout from "../components/layout"
import { Grid, Box, Embed } from "theme-ui"

function WorkPage() {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)
  const videos = [
    {
      url: "https://player.vimeo.com/video/604412278?h=1ed18f8e31",
      alt: "Beyonce's March 2013 Vogue Magazine Cover Shoot",
    },
    {
      url: "https://player.vimeo.com/video/604330566?h=c4679f582e",
      alt: "SAM FALCONI REAL ESTATE REEL",
    },
    {
      url: "https://player.vimeo.com/video/697823158?h=ec3c45cce5",
      alt: "IVY PARK",
    },
    {
      url: "https://player.vimeo.com/video/688073696?h=1c6196f7bd",
      alt: 'CARMEN BORLA - "Shadows" (Live for NPR Tiny Desk Contest 2022)',
    },
    {
      url: "https://player.vimeo.com/video/604412039?h=2b0e0833c3",
      alt: "22 Days Nutrition",
    },
    {
      url: "https://player.vimeo.com/video/640065862?h=48353a13ef",
      alt: "SUNDAY MELODY",
    },
    {
      url: "https://player.vimeo.com/video/604326231?h=a045e45e42",
      alt: "DJ MARSHMELLO",
    },
    {
      url: "https://player.vimeo.com/video/617419587?h=b55b171238",
      alt: "Cache",
    },
    {
      url: "https://player.vimeo.com/video/604412250?h=2cd045d2a1",
      alt: "CR FASHION MAG",
    },
    {
      url: "https://player.vimeo.com/video/604324475?h=180032153e",
      alt: "STAY IN LOVE",
    },
    {
      url: "https://player.vimeo.com/video/604412200?h=d14eb6b97a",
      alt: "Behind The Scenes Beyonce Beat Magazine PT1",
    },
    {
      url: "https://player.vimeo.com/video/604326949?h=18294a12e3",
      alt: "Sugar",
    },
    {
      url: "https://player.vimeo.com/video/604412646?h=e3d667e340",
      alt: "Homeless Valentine",
    },
    {
      url: "https://player.vimeo.com/video/604412743?h=5b584ca456",
      alt: "Beyonce Walk to Stage",
    },
  ]

  return (
    <Layout burgerIsOpen={burgerIsOpen} setBurgerIsOpen={setBurgerIsOpen}>
      <Grid gap={5} sx={{ width: "97%", mx: "auto" }} columns={[1, 1, 1, 2]}>
        {videos.map(video => {
          return (
            <Box key={video.alt} bg="primary">
              <Embed
                src={video.url}
                alt={video.alt}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              />
            </Box>
          )
        })}
      </Grid>
    </Layout>
  )
}

export default WorkPage
